
import React, { useContext } from 'react';
import { nonAuthInitialState, authReducer, AuthState, AuthActions } from './auth.state';
import { RemoteError, RemoteMethods, RemoteMethodsImpl } from './remoteMethods';
import { AccessLevels, User } from './remote.service';
import { withLogger } from 'Components/reduxLogger';

/**
 * useRemote()
 * Context with info about authentication status (including username, access level) 
 * and methods to login, logout, fetch resources. 
 */

export type { Credentials } from './remote.service';

const storageKey = 'auth';

const authReducerWithMiddleware = process.env.NODE_ENV === 'development' ? withLogger<AuthState, AuthActions>(authReducer) : authReducer;

function getStoredAuth(): { user: User, token: string } | undefined {
  const fromStorage = localStorage.getItem(storageKey);
  if (fromStorage) {
    try {
      const auth = JSON.parse(fromStorage)
      console.log("Auth from storage:", auth);
      return auth;
    } catch (err) {
      console.log('Error parsing stored auth:', err);
      return undefined;
    }
  }
}

const storedAuth = getStoredAuth();

const initialAuthState: AuthState = storedAuth ? { 
    ...storedAuth, 
    isAuthenticated: true, 
    isPending: false, 
    error: undefined, 
    isChangingPassword: false 
  } : nonAuthInitialState;

const _Methods = new RemoteMethodsImpl(storageKey, () => {/* */ }, undefined);

const defaultContext = [nonAuthInitialState, _Methods] as [AuthState, RemoteMethods];
export type Remote = typeof defaultContext;
export const RemoteContext: React.Context<Remote> = React.createContext(defaultContext);
RemoteContext.displayName = "Remote"

export function RemoteProvider(props: { children?: React.ReactNode }) {

  const [auth, dispatch] = React.useReducer(authReducerWithMiddleware, initialAuthState);

  const methods = React.useMemo(() => {
    _Methods.dispatch = dispatch;
    _Methods.state = auth;
    return _Methods;
  }, [auth, dispatch])
  
  return (
    <RemoteContext.Provider value={[auth, methods]}>
      {props.children}
    </RemoteContext.Provider>
  )
}

export const useRemote = () => useContext(RemoteContext);
export const useAuthState = () => useContext(RemoteContext)[0];
export const useRemoteMethods = () => useContext(RemoteContext)[1];

export const RemoteConsumer = RemoteContext.Consumer;

export const useAccessLevel = () => {
  const auth = useRemote()[0];
  return auth.isAuthenticated ? auth.user.accessLevel : AccessLevels.Guest
}


export { AccessLevels } from './remote.service';