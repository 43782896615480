import React from 'react';
import {nonAuthInitialState, AuthState, AuthActions} from './auth.state';
import {Credentials, service, FetchPromise, Abort, AssertAborted} from './remote.service';

/**
 * Functions to log into a remote service and fetch its resources.
 */
export interface RemoteMethods {

    /**
     * Authenticates with the remote service,
     * saves authentication data (including token) in auth state.
     */
    login: (credentials: Credentials, rememberMe: boolean) => Promise<boolean>,

    /**
     * Verifies the token given by the ActiveDirectoryLogin
     */
    activeDirectoryLogin: (credentials: Credentials) => Promise<boolean>,

    changePassword: (credentials: Credentials) => Promise<boolean>,

    /**
     * Authenticates with the remote service,
     * saves authentication data (including token) in auth state.
     * Returns cleanup function that aborts the login HTTP request, for use by React components when unmounting.
     */
    cleanLogin: (credentials: Credentials, rememberMe: boolean) => [Promise<boolean>, Abort],

    logout: () => Promise<void>,

    /**
     * Fetches given endpoint using user credentials if user is authenticated.
     * Returns promise of the response body.
     * If response is 401 resets authentication state.
     * If no response received, or the response status is is not in the ok range (200-299),
     * the promise is rejected and a RemoteError object is returned, with prop. error containing
     * the serialized response body if a response was received, or a TypeError if no response received.
     */
    fetch: (endpoint: string, params: RequestInit) => Promise<any>,

    /**
     * Fetches given endpoint using using user credentials if user is authenticated.
     * Returns promise of the response body, and cleanup function that aborts the HTTP request, for use by React component when unmounting.
     * If response is 401 resets authentication state.
     * If no response received, or the response status is is not in the ok range (200-299),
     * the promise is rejected and a RemoteError object is returned, with prop. error containing
     * the serialized response body if a response was received, or a TypeError if no response received.
     */
    cleanFetch: (endpoint: string, params?: RequestInit) => FetchPromise<any>
}

export interface RemoteError {
    type: string,
    details: any
}

export class RemoteMethodsImpl implements RemoteMethods {

    dispatch: React.Dispatch<AuthActions>;
    state: AuthState;

    private _storageKey: string;

    private _clearStoredAuth() {
        console.log("Clearing auth token in local storage");
        if (localStorage.getItem(this._storageKey)) {
            localStorage.removeItem(this._storageKey);
        }
    }

    constructor(storageKey: string, dispatch: React.Dispatch<AuthActions>, state: AuthState = nonAuthInitialState) {
        this._storageKey = storageKey;
        this.dispatch = dispatch;
        this.state = state;
        this
    }

    login = (credentials: Credentials, rememberMe = false, changePassword = false): Promise<boolean> => {

        const {dispatch, _storageKey} = this;
        dispatch({type: 'LOGIN_REQUEST'});
        const $result = changePassword
            ? service.changePasswordAndLogin(credentials)
            : service.login(credentials);
        return $result.then(
            (result) => {
                // const token = data.token;
                // const decoded = jwt_decode(token);
                //console.log('TOKEN', decoded);
                dispatch({type: 'LOGIN_SUCCESS', payload: result});
                if (rememberMe) {
                    localStorage.setItem(_storageKey, JSON.stringify(result));
                }
                return true;
            },
            (err) => {
                dispatch({type: 'LOGIN_FAILURE', payload: {error: err}});
                return false;
            }
        )
    };

    activeDirectoryLogin = async (credentials: Credentials): Promise<boolean> => {
        const {dispatch, _storageKey} = this;
        dispatch({type: 'LOGIN_REQUEST'});
        if (!credentials.activeDirectoryToken) {
            dispatch({type: 'LOGIN_FAILURE', payload: {error: "activeDirectoryLogin() Error: Missing token"}});
            throw new Error("activeDirectoryLogin() Error: Missing token")
        }
        try {
            const result = await service.login(credentials)
            dispatch({type: 'LOGIN_SUCCESS', payload: result});
            return true;
        } catch (exception) {
            dispatch({type: 'LOGIN_FAILURE', payload: {error: exception}});
            throw exception
        }
    };

    changePassword = (credentials: Credentials): Promise<boolean> => {

        const {dispatch, _storageKey} = this;
        dispatch({type: 'LOGIN_CHANGE_REQUEST'});
        const $result = service.changePassword(credentials);
        return $result.then(
            (result) => {
                dispatch({type: 'LOGIN_CHANGE_SUCCESS'});
                return true;
            },
            (err) => {
                dispatch({type: 'LOGIN_CHANGE_FAILURE', payload: {error: err}});
                return false;
            }
        )
    };

    cleanLogin = (credentials: Credentials, rememberMe = false): [Promise<boolean>, Abort] => {

        const {dispatch, _storageKey} = this;
        dispatch({type: 'LOGIN_REQUEST'});
        const [$response, abort] = service.abortableLogin(credentials);
        const $result = $response.then(
            (data) => {
                // const token = data.token;
                // const decoded = jwt_decode(token);
                //console.log('TOKEN', decoded);
                dispatch({type: 'LOGIN_SUCCESS', payload: data});
                if (rememberMe) {
                    localStorage.setItem(_storageKey, JSON.stringify(data));
                }
                return true;
            },
            (err) => {
                dispatch({type: 'LOGIN_FAILURE', payload: {error: err}});
                return false;
            }
        )
        return [$result, abort];
    };

    // logout = async () => {
    //   const { dispatch } = this;
    //   setTimeout(() => {
    //     this._clearStoredAuth();
    //     dispatch({ type: 'LOGOUT' })
    //   });
    // }

    logout = () => {
        const {dispatch} = this;
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                this._clearStoredAuth();
                dispatch({type: 'LOGOUT'})
            })
        });
    }

    private _handleResponse(response: Response) {

        if (response.status === 401) {
            this._clearStoredAuth();
            this.dispatch({type: 'LOGOUT'});
        }
        if (response.status === 404) {
            throw ({
                type: `${response.status} ${response.statusText}`,
            })
        }

        const respHeader = Object.fromEntries(response.headers);
        //console.log(response);

        /* Get response body */
        const respContentType = respHeader['content-type'];
        let $payload: Promise<any>;
        if (!respContentType) {
            $payload = new Promise((resolve, reject) => resolve(null));
        } else if (respContentType.startsWith('application/json')) {
            $payload = response.json();
        } else if (respContentType.includes('text')) {
            $payload = response.text();
        } else {
            $payload = response.blob();
        }

        if (response.ok) {
            return $payload;
        } else {
            return $payload.then(
                (payload) => {
                    throw ({
                        type: `${response.status} ${response.statusText}`,
                        details: payload
                    })
                },
                () => {
                    throw ({
                        type: `${response.status} ${response.statusText}`,
                    })
                }
            );
        }
    }

    private _handleNoResponse(err: TypeError) {
        const remoteErr: RemoteError = {
            type: err.name,
            details: err
        };
        throw (remoteErr);
    }

    //onError: (error: RemoteError) => void = () => { /* */ };
    onError = (err: RemoteError) => {
        console.log("Remote error", err?.type, ':', err?.details?.Message || err?.details || '<unspecified>');
    }

    fetch = async (endpoint: string, params: RequestInit = {}): Promise<any> => {

        const $fetchResponse = this.state.isAuthenticated
            ? service.authFetch(endpoint, this.state.token, params)
            : service.fetch(endpoint, params);

        return $fetchResponse.then(
            (response) => this._handleResponse(response),
            (err) => this._handleNoResponse(err)
        )
            .catch(err => {
                this.onError(err);
                throw err;
            })
        // @ts-ignore: noUnusedParameters
    }

    cleanFetch = (endpoint: string, params: RequestInit = {}): [Promise<any>, Abort, AssertAborted] => {
        const abortController = new AbortController();
        const $fetchResponse = this.state.isAuthenticated
            ? service.authFetch(endpoint, this.state.token, {...params, signal: abortController.signal})
            : service.fetch(endpoint, {...params, signal: abortController.signal});

        const result$ = $fetchResponse
            .then(
                (response) => this._handleResponse(response),
                (err) => this._handleNoResponse(err)
            )
            .catch(err => {
                this.onError(err);
                throw err;
            })

        const abort = () => {
            //console.log('Abort fetch ', endpoint);
            abortController.abort();
        }
        const assertAborted = () => abortController.signal.aborted;

        return [result$, abort, assertAborted];
    }
}

